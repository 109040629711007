// Track scrolling
document.addEventListener("scroll", () => {
    _paq.push(['trackEvent', 'Interaction', 'Scroll - ' + document.title]);
}, {once: true});

document.addEventListener("resize", () => {
    _paq.push(['trackEvent', 'Interaction', 'Resize - ' +  document.title]);
})

// Automatically track elements marked up with data-track-event attribute
// Get all elements with the data-track-content and data-track-event attributes
var contentBlocks = document.querySelectorAll('[data-track-event]');

// For each content block...
contentBlocks.forEach(function(block) {
    // Add a click event listener
    block.addEventListener('click', function() {
        // Get the content tracking attributes
        let name;
        if (block.getAttribute('data-content-name')){
            name = block.getAttribute('data-content-name');
        }
        else {
            name = "Unknown";
        }

        let piece;
        if (block.getAttribute('data-content-piece')){
            piece = block.getAttribute('data-content-piece');
        }
        else {
            piece = "Unknown";
        }

        let target;
        if (block.getAttribute('data-content-target')){
            target = block.getAttribute('data-content-target');
        }
        else if (block.href){
           target = block.href;
        }
        else {
            target = "Unknown";
        }

        // Send an event tracking call to Matomo
        _paq.push(['trackEvent', name, piece, target]);
    });
});
